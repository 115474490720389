<template>
  <div></div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newsletterLinkParses",
  props : {
    url : {
      type : String,
      default : null
    }
  },
  methods : {
    ...mapActions('newsletter', [
      'MARK_RECIEVER_AS_READ'
    ]),
  },
  async mounted() {
    this.$store.commit('GET_QUERY_PARAMS')
    let receiverId = decodeURIComponent(this.$store.state.QUERY.newsletter)
    let url = decodeURIComponent(this.$store.state.QUERY.url)

    await this.MARK_RECIEVER_AS_READ({uuid: receiverId, url: url})

    if ( url ) {
      window.location = url
    }




  }
}
</script>

<style scoped>

</style>